import { template } from 'lodash';

const compiledOrbitProtectLeadTemplate = template(`
<div>
  <div>Hi,</div>
  <br />
  <div>
    A new OrbitProtect lead has been generated from nib, with the following details:
  </div>
  <br />
  <div>
    <ul>
      <li>Adviser first name: <%- data.yourFirstName %></li>
      <li>Adviser family name: <%- data.yourFamilyName %></li>
      <li>Adviser nib UAN: <%- data.yourNibUan %></li>
      <li>Adviser email: <%- data.yourEmail %></li>
      <li>Adviser contact number: <%- data.yourPhoneNumber %></li>
    </ul>
    <br />
    <ul>
      <li>Client first name: <%- data.clientFirstName %></li>
      <li>Client family name: <%- data.clientFamilyName %></li>
      <li>Client date of birth: <%- data.clientDateOfBirth %></li>
      <li>Client confirmation: <%- data.confirmation %></li>
    </ul>
  </div>
  <br />
  <div>Thanks</div>
  <div>The team at nib NZ Limited
  </div>
</div>
`);

export type OrbitProtectLeadFormValues = {
  yourFirstName?: string;
  yourFamilyName?: string;
  yourNibUan?: string;
  yourEmail?: string;
  // yourBankAccount?: string;
  // GSTRegistered?: string;
  // GSTNumber?: string;
  yourPhoneNumber?: string;
  clientFirstName?: string;
  clientFamilyName?: string;
  clientDateOfBirth?: string;
  confirmation?: boolean;
};

export const generateOrbitProtectLeadEmailBody =
  (data: OrbitProtectLeadFormValues) => (referenceId) => {
    return compiledOrbitProtectLeadTemplate({ data, referenceId });
  };
