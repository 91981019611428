import React from 'react';
import Helmet from 'react-helmet';
import { Formik } from 'formik';

import Heading from '@nib-components/heading';
import { Stack, Box, Columns, Column, Section } from '@nib/layout';
import Copy from '@nib-components/copy';
import Card from '@nib/card';
import Form from '@nib-components/form';
import { PrimaryButton } from '@nib-components/button';
import Container from '@nib-components/container';
import FormControl from '@nib-components/form-control';
import Textbox from '@nib-components/textbox';
import Checkbox from '@nib-components/checkbox';
import DateTextbox from '@nib-components/date-textbox';
import Alert from '@nib-components/alert';
import Link from '@nib-components/link';
import { CommErrorMessage } from '../components/shared/ContactUsApiCommon';

import { validateEmail } from '../utils/validation';
import Layout from '../components/Layout';
import metrics from '../metrics';
import Image0 from '../img/orbitprotect/OrbitProtect_hero_image_Experience_NZ.jpg';
import Image1 from '../img/orbitprotect/OrbitProtect_hero_image_International_Student.jpg';
import Image2 from '../img/orbitprotect/OrbitProtect_hero_image_Working_Holiday.jpg';
import { postContactUsEmail } from '../components/shared/ContactUsApiService';
import {
  generateOrbitProtectLeadEmailBody,
  OrbitProtectLeadFormValues,
} from '../components/shared/contactUsContent/orbitProtectLeadEmail';

import config from '../utils/env';

const title = 'OrbitProtect';
const commType = 'orbitprotect';
const commDescription = 'A new OrbitProtect lead has been generated.';

const HeaderSection = (): JSX.Element => (
  <Section>
    <Stack space={7}>
      <Heading
        align="center"
        color="trueGreen"
        size={{ xs: 2, sm: 1 }}
        fontType="serif"
        component="h1"
      >
        {title}
      </Heading>
      <WhatIsItSection />
      <PaneSection />
    </Stack>
  </Section>
);

const WhatIsItSection = (): JSX.Element => {
  return (
    <Container>
      <Stack space={4}>
        <Box textAlign="left">
          <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="p">
            What is it?
          </Heading>
        </Box>
        <Copy align="left" measure={false}>
          When your client is visiting New Zealand, or is seeking to stay permanently but is not
          eligible for publicly funded healthcare given visa or residency requirements, offer them
          peace of mind with Medical and Travel Insurance from OrbitProtect.
        </Copy>
        <Copy align="left" measure={false}>
          {' '}
          Whether staying in New Zealand for three days or up to 12 months you can ensure your
          client has quality protection made to fit their budget and lifestyle.
        </Copy>
      </Stack>
    </Container>
  );
};

const PaneSection = (): JSX.Element => (
  <Container>
    <Stack space={4}>
      <Box textAlign="left">
        <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="h2">
          What Medical and Travel Insurance options are available?
        </Heading>
      </Box>
      <Columns space={{ xs: 2, lg: 4 }} collapseBelow="md">
        <Column flex>
          <Card image={Image0}>
            <Card.Content title="Experience NZ Insurance">
              <Copy>
                <ul>
                  <li>For all visa types and for families</li>
                  <li>Choose from Prime or Lite options</li>
                </ul>
              </Copy>
            </Card.Content>
          </Card>
        </Column>
        <Column flex>
          <Card image={Image1}>
            <Card.Content title="International Student Insurance">
              <Copy>
                <ul>
                  <li>For international students only</li>
                  <li>Choose from Prime or Lite options</li>
                </ul>
              </Copy>
            </Card.Content>
          </Card>
        </Column>
        <Column flex>
          <Card image={Image2}>
            <Card.Content title="Working Holiday Insurance">
              <Copy>
                <ul>
                  <li>For holders of working holiday visas¹ only</li>
                </ul>
              </Copy>
            </Card.Content>
          </Card>
        </Column>
      </Columns>
      <Copy small measure={false}>
        ¹Or you can upgrade to one of the Experience NZ Insurance options
      </Copy>
    </Stack>
  </Container>
);

const FormSection = (): JSX.Element => {
  type FormErrors = {
    yourFirstName?: string;
    yourFamilyName?: string;
    yourNibUan?: string;
    yourEmail?: string;
    yourBankAccount?: string;
    GSTRegistered?: string;
    GSTNumber?: string;
    yourPhoneNumber?: string;
    clientFirstName?: string;
    clientFamilyName?: string;
    clientDateOfBirth?: string;
    confirmation?: string;
  };

  const initialValues: OrbitProtectLeadFormValues = {
    yourFirstName: '',
    yourFamilyName: '',
    yourNibUan: '',
    yourEmail: '',
    yourPhoneNumber: '',
    //* For ticket NZDS-4388 Orbit form -> to be re-enalbed once unblocked */
    // yourBankAccount: '',
    // GSTRegistered: '',
    // GSTNumber: '',
    clientFirstName: '',
    clientFamilyName: '',
    clientDateOfBirth: '',
    confirmation: false,
  };

  const validate = (values: OrbitProtectLeadFormValues): FormErrors => {
    let errors: FormErrors = {};
    if (!values.yourFirstName) errors.yourFirstName = 'Required';
    if (!values.yourFamilyName) errors.yourFamilyName = 'Required';
    if (!values.yourNibUan) errors.yourNibUan = 'Required';
    if (!values.yourEmail) {
      errors.yourEmail = 'Required';
    } else if (!validateEmail(values.yourEmail)) {
      errors.yourEmail = 'Invalid email';
    }
    if (!values.yourPhoneNumber) errors.yourPhoneNumber = 'Required';
    {
      /* For ticket NZDS-4388 Orbit form -> to be re-enalbed once unblocked */
    }
    // const bankValidationResult = validateBankAccount(values.yourBankAccount);
    // if (bankValidationResult.valid) {
    //   errors.yourBankAccount = '';
    // } else {
    //   errors.yourBankAccount = bankValidationResult.error;
    // }
    // if (!values.GSTRegistered) errors.GSTRegistered = 'Required';
    // if (values.GSTRegistered === 'yes' && values.GSTNumber?.length === 0)
    //   errors.GSTNumber = 'Required';
    if (!values.clientFirstName) errors.clientFirstName = 'Required';
    if (!values.clientFamilyName) errors.clientFamilyName = 'Required';
    if (!values.clientDateOfBirth) errors.clientDateOfBirth = 'Required';
    if (!values.confirmation) errors.confirmation = 'Required';

    return errors;
  };

  const generateRedirectUrl = (values) => {
    if (!config.orbitProtectUrl) return null;
    const qsKeys = {
      AdviserFirstName: 'yourFirstName',
      AdviserFamilyName: 'yourFamilyName',
      AdviserUan: 'yourNibUan',
      AdviserEmail: 'yourEmail',
      AdviserPhone: 'yourPhoneNumber',
      ClientFirstName: 'clientFirstName',
      ClientFamilyName: 'clientFamilyName',
    };
    const qsPairs = Object.entries(qsKeys).map(([qsKey, valueKey]) => [qsKey, values[valueKey]]);
    qsPairs.push(['referrer', config.orbitProtectReferrerId]);
    const [, d, m, y] = values.clientDateOfBirth.match(/^(\d\d)\/(\d\d)\/(\d\d\d\d)$/) || [];
    qsPairs.push(['ClientDob.Day', d]);
    qsPairs.push(['ClientDob.Month', m]);
    qsPairs.push(['ClientDob.Year', y]);
    const queryString = qsPairs.map(([qsKey, qsValue]) => `${qsKey}=${qsValue ?? ''}`).join('&');

    return `${config.orbitProtectUrl}?${queryString}`;
  };

  const onSubmit = (values, { setSubmitting, setStatus, resetForm }) => {
    const recipient = values.yourEmail || '';
    const redirectUrl = generateRedirectUrl(values);
    postContactUsEmail(
      commType,
      commDescription,
      '',
      '',
      [recipient],
      generateOrbitProtectLeadEmailBody(values)
    )
      .then(function () {
        resetForm({});
        setStatus({
          submitSucceeded: true,
          errorMessage: null,
          redirectUrl,
        });
      })
      // wait 2 seconds for the user to read the success message
      .then(() => new Promise((resolve) => setTimeout(resolve, 2000)))
      .then(() => {
        if (redirectUrl) {
          window.location.href = redirectUrl;
        }
      })
      .catch(function (error) {
        setSubmitting(false);
        setStatus({
          submitSucceeded: false,
          errorMessage: error.message,
          redirectUrl: null,
        });
      });
  };

  const voidOnChange = () => {};

  const contactForm = ({
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    isValid,
    status,
  }): JSX.Element => (
    <Stack space={{ xs: 2, md: 4 }}>
      <Box textAlign="left">
        <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="p">
          What do you do next?
        </Heading>
      </Box>
      <Form
        id="orbit-protect-form"
        name="orbitProtectForm"
        onSubmit={handleSubmit}
        containerWidth="100%"
        style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }}
      >
        <Copy measure={false}>
          Please provide the following details and then click the button below to find, and apply
          for, the OrbitProtect plan most suited to your client’s needs.
        </Copy>
        <Heading size={4} color="trueGreen" component="p">
          Adviser details:
        </Heading>
        <Columns
          space={{ xs: 2, lg: 4 }}
          collapseBelow="md"
          align={{ xs: 'center', sm: 'left', md: 'left', lg: 'left' }}
          verticalAlign="top"
        >
          <Column width="1/3">
            <FormControl
              id="yourFirstName"
              name="yourFirstName"
              label="Your first name"
              onChange={handleChange}
              value={values?.yourFirstName}
              error={errors?.yourFirstName}
              valid={!errors.yourFirstName}
              validated={touched.yourFirstName}
            >
              <Textbox autoComplete="given-name" />
            </FormControl>
          </Column>
          <Column width="1/3">
            <FormControl
              id="yourFamilyName"
              name="yourFamilyName"
              label="Your family name"
              onChange={handleChange}
              value={values?.yourFamilyName}
              error={errors.yourFamilyName}
              valid={!errors.yourFamilyName}
              validated={touched.yourFamilyName}
            >
              <Textbox autoComplete="family-name" />
            </FormControl>
          </Column>
          <Column width="1/3">
            <FormControl
              id="yourNibUan"
              name="yourNibUan"
              label="Your nib UAN"
              onChange={handleChange}
              value={values?.yourNibUan}
              error={errors?.yourNibUan}
              valid={!errors.yourNibUan}
              validated={touched.yourNibUan}
            >
              <Textbox />
            </FormControl>
          </Column>
        </Columns>
        <Columns
          space={{ xs: 2, lg: 4 }}
          collapseBelow="md"
          align={{ xs: 'center', sm: 'left', md: 'left', lg: 'left' }}
          verticalAlign="top"
        >
          <Column width="1/3">
            <FormControl
              id="yourEmail"
              name="yourEmail"
              label="Your email"
              onChange={handleChange}
              value={values?.yourEmail}
              error={errors?.yourEmail}
              valid={!errors.yourEmail}
              validated={touched.yourEmail}
            >
              <Textbox autoComplete="email" />
            </FormControl>
          </Column>
          <Column width="1/3">
            <FormControl
              id="yourPhoneNumber"
              name="yourPhoneNumber"
              label="Your phone number"
              onChange={handleChange}
              value={values?.yourPhoneNumber}
              error={errors.yourPhoneNumber}
              valid={!errors.yourPhoneNumber}
              validated={touched.yourPhoneNumber}
            >
              <Textbox autoComplete="tel" />
            </FormControl>
          </Column>
        </Columns>
        {/* For ticket NZDS-4388 Orbit form -> to be re-enalbed once unblocked */}
        {/* <Columns
          space={{ xs: 2, lg: 4 }}
          collapseBelow="md"
          align={{ xs: 'center', sm: 'left', md: 'left', lg: 'left' }}
          verticalAlign="top"
        >
          <Column width="1/3">
            <FormControl
              id="yourBankAccount"
              name="yourBankAccount"
              label="Your New Zealand bank account number"
              onChange={handleChange}
              value={values?.yourBankAccount}
              error={errors?.yourBankAccount}
              valid={!errors.yourBankAccount}
              validated={touched.yourBankAccount}
            >
              <Textbox autoComplete="bank-account" />
            </FormControl>
          </Column>
          <Column width="1/3">
            <FormControl
              id="GSTRegistered"
              name="GSTRegistered"
              label="Are you GST registered?"
              onChange={handleChange}
              value={values?.GSTRegistered}
              error={errors?.GSTRegistered}
              valid={!errors.GSTRegistered}
              validated={touched.GSTRegistered}
            >
              <RadioGroup
                options={{
                  yes: 'Yes',
                  no: 'No',
                }}
              />
            </FormControl>
          </Column>
          <Column width="1/3">
            <FormControl
              id="GSTNumber"
              name="GSTNumber"
              label="GST Number"
              onChange={handleChange}
              value={values?.GSTNumber}
              error={errors?.GSTNumber}
              valid={!errors.GSTNumber}
              validated={touched.GSTNumber}
              disabled={values?.GSTRegistered === '' ? true : values?.GSTRegistered === 'no'}
            >
              <Textbox autoComplete="gst-number" />
            </FormControl>
          </Column>
        </Columns> */}
        <Heading size={4} color="trueGreen" component="p">
          Your client's details:
        </Heading>
        <Columns
          space={{ xs: 2, lg: 4 }}
          collapseBelow="md"
          align={{ xs: 'center', sm: 'left', md: 'left', lg: 'left' }}
          verticalAlign="top"
        >
          <Column width="1/3">
            <FormControl
              id="clientFirstName"
              name="clientFirstName"
              label="Client's first name"
              onChange={handleChange}
              value={values?.clientFirstName}
              error={errors.clientFirstName}
              valid={!errors.clientFirstName}
              validated={touched.clientFirstName}
            >
              <Textbox autoComplete="given-name" />
            </FormControl>
          </Column>
          <Column width="1/3">
            <FormControl
              id="clientFamilyName"
              name="clientFamilyName"
              label="Client's family name"
              onChange={handleChange}
              value={values?.clientFamilyName}
              error={errors.clientFamilyName}
              valid={!errors.clientFamilyName}
              validated={touched.clientFamilyName}
            >
              <Textbox autoComplete="family-name" />
            </FormControl>
          </Column>
          <Column width="1/3">
            <FormControl
              id="clientDateOfBirth"
              name="clientDateOfBirth"
              label="Client's date of birth"
              error={errors.clientDateOfBirth}
              valid={!errors.clientDateOfBirth}
              validated={touched.clientDateOfBirth}
              onChange={(event) => {
                event.target.id = 'clientDateOfBirth';
                handleChange(event);
              }} // Add the expected 'id' property to the event
              value={values?.clientDateOfBirth}
            >
              <DateTextbox
                maxDate={new Date()}
                autoComplete="bday"
                label=""
                onChange={voidOnChange}
              />
            </FormControl>
          </Column>
        </Columns>
        <Container>
          <FormControl
            id="confirmation"
            name="confirmation"
            width={'100%'}
            label=""
            onChange={handleChange}
            error={errors.confirmation}
            valid={!errors.confirmation}
            validated={touched.confirmation}
          >
            <Checkbox
              width="100%"
              label="I’ve read and agree with the nib Privacy Policy. I agree that, and have my client’s authority to agree that, nib is authorised to disclose this information about me and my client to nib and to OrbitProtect Limited. I also give nib authority to provide to OrbitProtect details of the agency record and bank account information they hold in relation to my nib UAN that I have entered above for the purposes of commission payment."
              value="confirm"
              checked={values?.confirmation as boolean}
            />
          </FormControl>
        </Container>
        {status && !status.submitSucceeded && status.errorMessage && (
          <Alert type="error" title="Oops, something went wrong" fullWidth={true}>
            <CommErrorMessage />
          </Alert>
        )}
        {status?.submitSucceeded && (
          <Alert type="success" title="Thanks!" fullWidth={true}>
            <Copy small measure={false}>
              We have recorded those details.
              {status.redirectUrl && (
                <>
                  We are now taking you to the{' '}
                  <Link href={status.redirectUrl}>OrbitProtect site</Link> to complete your client’s
                  application.
                </>
              )}
            </Copy>
          </Alert>
        )}
        {!status?.submitSucceeded && (
          <PrimaryButton type="submit" disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : 'Find your plan'}
          </PrimaryButton>
        )}
      </Form>
    </Stack>
  );

  return (
    <Section background="sunsetPink60">
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={(values, formikHelpers) => {
          onSubmit(values, { ...formikHelpers });
        }}
      >
        {contactForm}
      </Formik>
    </Section>
  );
};

const schema = {
  '@context': 'https://schema.org',
  '@type': 'WebPage',
  '@id': 'WebPage',
  identifier: 'https://www.nib.co.nz/orbitprotect',
  url: 'https://www.nib.co.nz/orbitprotect',
  description: 'Great value health insurance',
  name: ['OrbitProtect | nib'],
  isPartOf: 'https://www.nib.co.nz',
};
const JSONschema = JSON.stringify(schema);

const OrbitProtect = (): JSX.Element => (
  <Layout>
    <Helmet>
      <title>OrbitProtect Insurance | nib</title>
      <script type="application/ld+json">{JSONschema}</script>
    </Helmet>
    <HeaderSection />
    <FormSection />
  </Layout>
);

export default metrics({ pageName: 'orbitprotect' })(OrbitProtect);
